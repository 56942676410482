<template>
  <div>
    <List
      :fields="fields"
      modelName="Guía de remisión"
      create_url="core_category_create"
      backend_url="core/dispatch"
      model="dispatch"
      :is_paginated="true"
    ></List>
  </div>
</template>

<script>
import List from "@/view/pages/crud/List";

export default {
  name: "DespatchList",
  components: { List },
  data() {
    return {
      fields: [
        { key: "serie", label: "Serie" },
        { key: "number", label: "Número" },
        { key: "related_doc", label: "Doc. relacionado" },
      ],
    };
  },
};
</script>

<style scoped></style>
